export const colors: any = {
  primary: 'green',
  red: '#FA4169',
  black: '#000000',
  white: '#FFFFFF',
  dark: '#11142D',
  gray: '#808191',
  lightGray: '#F7F7F7',
  disabled: '#d3d3d3',
};
